import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { Col } from 'react-bootstrap';
import {
  Control,
  FieldValues,
  FormState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import useDebounce from '~/hooks/useDebounce';
import api from '~/services/api';
import { InputAsyncSelect } from '../InputAsyncSelect';
import { InputNumber } from '../InputNumber';

export type CalculoResponse = {
  condicao: {
    value: number;
    label: string;
  };
  numDias: number;
  vencimento: string;
  error?: string;
};
interface CalculoCondicaoProps {
  name: string;
  changeCondicao(novaData: CalculoResponse): any;
  dataEmissao: string;
  dataEntrada: string;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  formState: FormState<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  disabled?: boolean;
  fieldToWatch?: string; // Caso queria que o cálculo da confição seja refeito quando um campo for alterado
}

export const CalculoCondicao: React.FC<CalculoCondicaoProps> = (props) => {
  const {
    // name,
    changeCondicao,
    dataEmissao,
    dataEntrada,
    register,
    control,
    setValue,
    getValues,
    formState,
    watch,
    disabled,
    fieldToWatch = '',
  } = props;
  const { debouncedFn } = useDebounce();
  const watchDias = watch('num_condicao');
  const watchCondicao = watch('condicao');
  const watchFinalizadora = watch(fieldToWatch);

  const recalculaVencimento = useCallback(async () => {
    const condicaoIsUpdate = getValues('condicao_update');
    if (!condicaoIsUpdate) {
      return;
    }
    const { num_condicao, condicao } = getValues();

    if (dataEmissao === '' || dataEntrada === '') {
      changeCondicao({
        vencimento: '',
        numDias: num_condicao,
        condicao,
        error: 'Datas Devem ser informadas.',
      });
      return;
    }

    if (condicao && num_condicao && condicao !== '' && num_condicao !== '') {
      try {
        const { data } = await api.post(`/calcula-condicao`, {
          cod_condicao: condicao.value,
          dataEmissao,
          dataEntrada,
          dias: num_condicao,
          diaBase: 0,
        });
        if (data.success && data.data) {
          changeCondicao({
            vencimento: data.data,
            numDias: num_condicao,
            condicao,
          });
          return;
        }
        toast.error('Não foi possível calcular a data de vencimento.');
      } catch (error: any) {
        if (error.data && error.data.message) {
          toast.error(error.data.message);
        }
        toast.error(String(error));
      }
    }
  }, [changeCondicao, dataEmissao, dataEntrada, getValues]);

  /**
   * O método diasComDebounce é utilizado para chamar o método recalculaVencimento apenas após o usuário terminar de digitar.
   */
  const diasComDebounce = useCallback(() => {
    const condicaoIsUpdate = getValues('condicao_update');
    if (!condicaoIsUpdate) {
      return;
    }
    debouncedFn(() => recalculaVencimento(), 500);
  }, [debouncedFn, getValues, recalculaVencimento]);

  /**
   * O useLayoutEffect abaixo é executado sempre que o input Dias for alterado.
   * No escopo do useEffect é chamado o método diasComDebounce.
   * O método diasComDebounce é utilizado para chamar o método recalculaVencimento apenas após o usuário terminar de digitar.
   */
  useLayoutEffect(() => {
    const condicaoIsUpdate = getValues('condicao_update');
    if (!condicaoIsUpdate) {
      return;
    }
    if (
      !formState.dirtyFields.num_condicao ||
      formState.dirtyFields.num_condicao !== true
    ) {
      return;
    }
    diasComDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.dirtyFields, watchDias]);

  /**
   * Define valores iniciais
   */
  useEffect(() => {
    setValue('condicao', {
      value: 2,
      label: '2 - DD - (DIAS DA DATA)',
      cod_condicao: 2,
      des_condicao: 'DIAS DA DATA',
      des_definicao: 'DD',
    });
    const numCondicao = getValues('num_condicao');
    if (numCondicao === undefined || numCondicao === '' || numCondicao === 30) {
      setValue('num_condicao', 30);
    }
  }, [getValues, setValue]);

  useEffect(() => {
    const condicaoIsUpdate = getValues('condicao_update');
    if (!condicaoIsUpdate) {
      if (watchFinalizadora !== '') {
        recalculaVencimento();
      }
    }
  }, [watchFinalizadora]);

  useEffect(() => {
    if (!watchCondicao || watchCondicao === '') {
      setValue('condicao', {
        value: 2,
        label: 'DD - DIAS DA DATA',
        cod_condicao: 2,
        des_condicao: 'DIAS DA DATA',
        des_definicao: 'DD',
      });
    }
  }, [watchCondicao]);

  return (
    <>
      <Col sm={1}>
        <InputNumber
          label="Dias"
          max={999}
          min={1}
          maxLength={3}
          placeholder="0"
          name="num_condicao"
          register={register}
          disabled={disabled}
          isError={!!formState.errors.num_condicao}
        />
      </Col>
      <Col sm={4}>
        <InputAsyncSelect
          label="Condição"
          maxLength={50}
          placeholder="Selecione..."
          name="condicao"
          register={register}
          isError={!!formState.errors.condicao}
          control={control}
          disabled={disabled}
          changeSelected={(selected) => {
            setValue('condicao', selected);
            recalculaVencimento();
          }}
          api={{
            route: '/condicao',
            method: 'get',
            fields: ['cod_condicao', 'des_definicao', 'des_condicao'],
            searchBeforeFilter: true,
          }}
        />
      </Col>
    </>
  );
};
